/** @jsx jsx */
import { jsx, Container, Flex, Styled } from "theme-ui"

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header sx={{position: "fixed", zIndex: "9999", width: "100%", py: 4}}>
    <Container sx={{width: "100%", maxWidth: "max"}}>
      <Styled.a to="/" sx={{color: "light"}} >{siteTitle}</Styled.a>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
